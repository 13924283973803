@import 'editor/src/comonStyles/index';

.FlatPreviewAdvanced {
  display: flex;
  user-select: none;
  height: 100%;
  overflow: hidden;

  &.mobile {
    flex-direction: column;
    overflow-y: auto;

    .carouselContainer {
      min-width: 100%;
      flex-basis: 100%;
    }

    .imageThumbnailsContainer {
      width: 100%;
      max-width: 100%;
      overflow-x: auto;
      flex-wrap: nowrap;

      .imageThumbnail {
        flex-shrink: 0;
      }
    }

    .controlsContainer {
      overflow: unset;
    }

    .listItemsSection {
      overflow: unset;
    }

    .bottomActions {
      position: static;
    }

  }

  .imageThumbnailsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 386px;

    height: 100%;

    .imageThumbnail {
      width: 90px;
      height: 90px;
      position: relative;

      .imageThumbnailLoader {
        width: 100%;
      }

      .imageThumbnailContent {
        padding: 1px;
        position: relative;

        > div {
          border-radius: 8px;
          overflow: hidden;
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.carouselContainer {
  min-width: 667px;
  flex-basis: 667px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 42px;
}

.controlsContainer {
  display: flex;
  position: relative;
  min-width: 200px;
  flex-direction: column;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  overflow-y: auto;
  border-left: 1px solid #f4f4f4;
}

.controlsTitle {
  color: $neutral90;

  font-family: "Gelato Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;

  margin: 12px 0;
}

.groupFilters {
  margin-bottom: 8px;
}

.listItemsSection {
  overflow-y: auto;
}

.groupFiltersTitle {
  color: $neutral90;

  /* Label/Large - 14/Bold */
  font-family: "Gelato Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.32px;
}

.premiumIconContainer {
  position: absolute;
  bottom: 8px;
  right: 8px;
  user-select: none;
}

.bottomActions {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  flex-direction: row-reverse;
  margin: 8px 0;
  flex-wrap: wrap;
  gap: 8px;
  align-items: end;

  button {
    font-family: "Gelato Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 8px 0;

    border-radius: 24px;
  }
}